import { useState } from 'react'

import type { UseFormRegister } from 'react-hook-form'

import { lang } from '@lib/constants'

type PasswordInputProps = {
	register: UseFormRegister<any>
	validate?: (value: string) => boolean | string
	required?: boolean
	canToggleVisible?: boolean
	placeholder?: string
	id?: string
	errors?: string
}

export default function PasswordInput({
	placeholder = 'Password',
	id = 'password',
	register,
	validate,
	errors,
	canToggleVisible = false,
	required = true
}: PasswordInputProps) {
	const [inputType, setInputType] = useState<'password' | 'text'>('password')

	return (
		<div className="form-control">
			<input
				type={inputType}
				className={`input ${canToggleVisible ? 'pr-16' : ''}`}
				autoComplete="password"
				aria-label={placeholder}
				required
				id={id}
				// If no validation is provided, then automatically validate
				{...register(id, { required, validate: validate || undefined })}
			/>

			<label htmlFor={id} className="label">
				{placeholder}
			</label>

			{canToggleVisible && (
				<button
					type="button"
					onClick={() => setInputType((prev) => (prev === 'password' ? 'text' : 'password'))}
					className="password-view-toggle-button"
				>
					{lang === 'es-es' && (inputType === 'text' ? 'ocultar' : 'ver')}
					{lang !== 'es-es' && (inputType === 'text' ? 'hide' : 'view')}
				</button>
			)}
			{errors && <p className="text-sm font-medium">{errors}</p>}
		</div>
	)
}
