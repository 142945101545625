import { lang } from '@lib/constants'

// Loading Spinner
export default function Spinner({ classes = '' }: { classes?: string }) {
	return (
		<div className={`spinner ${classes}`}>
			<span className="sr-only">
				{lang === 'es-es' ? 'Cargando. Espere por favor' : 'Loading. Please wait'}&hellip;
			</span>
		</div>
	)
}
