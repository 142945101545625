import type { TextInputProps } from '@lib/types/form-controls'

export default function TextInput({
	placeholder,
	id,
	register,
	errors,
	autoComplete = '',
	required = true,
	inputMode = 'text',
	isFilled = false
}: TextInputProps) {
	return (
		<div className="flex flex-col">
			<div className="form-control">
				<input
					className={`input ${errors ? 'error' : ''} ${isFilled ? 'is-filled' : ''} ${
						required ? '' : 'optional'
					}`}
					type="text"
					id={id}
					autoComplete={autoComplete}
					required={required}
					{...register(id, { required })}
					inputMode={inputMode}
				/>
				<label htmlFor={id} className="label">
					{placeholder}
				</label>
			</div>
			{errors && <p className="py-2 error-message">{errors}</p>}
		</div>
	)
}
