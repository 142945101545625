import Link from 'next/link'

import AuthTextNodes from '@data/i18n/auth.json'
import CommonTextNodes from '@data/i18n/common.json'

export default function AuthFooter() {
	return (
		<p className="text-xs">
			{AuthTextNodes.footer.disclaimer[0]}{' '}
			<Link legacyBehavior href={CommonTextNodes.termsHref}>
				<a className="underline">{AuthTextNodes.footer.disclaimer[1]}</a>
			</Link>{' '}
			{AuthTextNodes.footer.disclaimer[2]}{' '}
			<Link legacyBehavior href={CommonTextNodes.privacyHref}>
				<a className="underline">{AuthTextNodes.footer.disclaimer[3]}</a>
			</Link>
			{AuthTextNodes.footer.disclaimer[4]}
		</p>
	)
}
