import { useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'

import { getCsrfToken, getSession, signIn } from 'next-auth/react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import useCanPurchaseSubscriptionBox from '@context/subscription-box/useCanPurchaseSubscriptionBox'
import useBrazeIntegration from '@context/useBrazeIntegration'
import useToast from '@context/useToast'
import type { SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'

import AuthTextNodes from '@data/i18n/auth.json'

import useRemoveItem from '@lib/cart/use-remove-item'
import { lang } from '@lib/constants'
import type { AuthProps } from '@lib/types/Auth'

import EmailInput from '@components/form-controls/EmailInput'
import PasswordInput from '@components/form-controls/PasswordInput'
import AuthFooter from '@components/profile/AuthFooter'

import NewPasswordBanner from './SignIn/NewPasswordBanner'

interface IFormInput {
	email: string
	password: string
	signInError: string
}

type SignInFormParams = {
	setAuthStep: Dispatch<SetStateAction<AuthProps['step']>>
	subscriptionBox: AuthProps['subscriptionBox']
}

export default function SignInForm({ subscriptionBox, setAuthStep }: SignInFormParams) {
	const [csrfToken, setcrsfToken] = useState('')
	const router = useRouter()
	const toast = useToast()

	const {
		revalidate: revalidateSubscriptionContract,
		subscriptionBoxInCart,
		loading: subscriptionBoxLoading
	} = useCanPurchaseSubscriptionBox()
	const removeItemFromCart = useRemoveItem()

	const { brazeFunction, getBrazeExternalId } = useBrazeIntegration()

	const {
		register,
		handleSubmit,
		watch,
		setError,
		clearErrors,
		formState: { errors }
	} = useForm<IFormInput>()

	// Watch email input to style when has value
	const emailInput = watch('email')

	useEffect(() => {
		async function fetchCsrfToken() {
			const newCsrfToken = await getCsrfToken()

			if (newCsrfToken) {
				setcrsfToken(newCsrfToken)
			}
		}

		fetchCsrfToken()
	}, [])

	const clearRouterQuery = () => {
		if (router?.query?.reason === 'expired') {
			delete router.query.reason
			router.replace(router, undefined, { shallow: true })
		}
	}

	const onSubmit: SubmitHandler<IFormInput> = async (data) => {
		const { email, password } = data

		const response = await signIn<'credentials'>('credentials', {
			redirect: false,
			email,
			password
		})

		if (response?.status !== 200) {
			setError('signInError', {
				type: 'shopify',
				message: AuthTextNodes.login.errors.tryAgain
			})
		}

		const session = await getSession()

		const brazeExternalId = getBrazeExternalId(session)

		if (brazeExternalId) {
			const braze = await brazeFunction()

			if (braze) {
				braze.changeUser(brazeExternalId)
				braze.getUser()?.setEmail(email)
				braze.requestImmediateDataFlush()
			}
		}

		// Configure the subscription box if in cart, and user already has subscription box purchased
		try {
			// Remove the subscription box if it's already in the cart
			// hack to get rechargeSession not depending on recharge hook
			const existingBoxId = await revalidateSubscriptionContract?.()

			// Remove item in cart if user has already purchased the subscription box
			if (existingBoxId && subscriptionBoxInCart?.cartLineId) {
				// User has already purchased the subscription box

				// Remove the subscription box from cart
				await removeItemFromCart({ id: subscriptionBoxInCart.cartLineId })

				// Redirect the user to `/profile` if they were filling out the survey
				if (subscriptionBox) {
					await router.push('/profile/subscriptions')
				}

				// Show toast explaining that the subscription box was removed from the cart
				toast(
					"You're already subscribed.",
					'Your subscription box was automatically removed from your cart.'
				)
			}
		} catch (error) {
			// This script is non-essential — shouldn't break the sign in flow
			console.log('Error removing subscription box from cart on sign in', error)
		}
	}

	return (
		<>
			{errors?.signInError && (
				<p className="py-2 pl-5 form error-message">{errors?.signInError?.message}</p>
			)}

			{router?.query?.reason === 'expired' && (
				<p className="py-2 pl-5 form error-message">{AuthTextNodes.expiredSessionMessage}</p>
			)}

			<form className="flex flex-col items-center" method="post" onSubmit={handleSubmit(onSubmit)}>
				<NewPasswordBanner />

				<div className="w-full max-w-sm flex flex-col justify-center gap-10">
					<input name="csrfToken" type="hidden" defaultValue={csrfToken} />

					{/* Email */}
					<EmailInput register={register} isFilled={!!emailInput} errors={errors?.email?.message} />

					{/* Password */}
					<PasswordInput register={register} errors={errors?.password?.message} />

					{/* Submit */}
					<button
						type="submit"
						className="button button-primary"
						onClick={() => {
							clearRouterQuery()
							return clearErrors('signInError')
						}}
					>
						{AuthTextNodes.login.cta}
					</button>
				</div>
			</form>

			<Link legacyBehavior href={lang === 'es-es' ? '/restablecer-contrasena' : '/reset-password'}>
				<a className="p-1 mx-auto mt-4 text-sm font-medium underline text-gray-500">
					{AuthTextNodes.forgotPassword.cta}
				</a>
			</Link>

			{/* Decorative Or with horizontal lines */}
			<div className="flex gap-x-1.5 items-center my-10">
				<div aria-hidden="true" className="w-full h-px bg-gray-200" />
				<p className="font-medium text-gray-500">{AuthTextNodes.or}</p>
				<div aria-hidden="true" className="w-full h-px bg-gray-200" />
			</div>

			{subscriptionBox ? (
				<button
					className="button button-secondary"
					type="button"
					onClick={() => setAuthStep('sign-up')}
				>
					{AuthTextNodes.signup.cta}
				</button>
			) : (
				<Link legacyBehavior href={lang === 'es-es' ? '/registrarse' : '/register'}>
					<a className="button button-secondary">{AuthTextNodes.signup.cta}</a>
				</Link>
			)}

			<div className="text-xs text-center max-w-[200px] mx-auto mt-24">
				<AuthFooter />
			</div>
		</>
	)
}
