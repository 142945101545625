import { useState } from 'react'

import AuthTextNodes from '@data/i18n/auth.json'

import type { AuthProps } from '@lib/types/Auth'

import RegistrationForm from './RegistrationForm'
import SignInForm from './SignInForm'

export default function Auth({ step = 'sign-up', subscriptionBox = false }: AuthProps) {
	const [authStep, setAuthStep] = useState(step)

	if (['sign-up', 'verify-phone'].includes(authStep)) {
		return (
			<div className="w-full max-w-sm">
				{!subscriptionBox && (
					<>
						<h2 className="text-3xl text-center">{AuthTextNodes.signUpTeaser.title}</h2>
						<p className="mt-2 mb-12 text-sm font-medium text-center text-gray-500">
							{AuthTextNodes.signUpTeaser.description}
						</p>
					</>
				)}
				<RegistrationForm
					subscriptionBox={subscriptionBox}
					setAuthStep={setAuthStep}
					authStep={authStep}
				/>
			</div>
		)
	}

	return (
		<div className="container flex flex-col items-center">
			{!subscriptionBox && (
				<>
					<h2 className="text-3xl text-center">{AuthTextNodes.title}</h2>
					<p className="mt-2 mb-12 text-sm font-medium text-center text-gray-500">
						{AuthTextNodes.description}
					</p>
				</>
			)}

			<SignInForm subscriptionBox={subscriptionBox} setAuthStep={setAuthStep} />
		</div>
	)
}
