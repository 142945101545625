// eslint-disable-next-line import/prefer-default-export
export const getRetryTimeout = (attemptNumber: number): number => {
	const retryTimeouts: { [n: number]: number } = {
		0: 0,
		1: 30,
		2: 40,
		3: 60,
		4: 90,
		5: 120
	}

	return retryTimeouts[attemptNumber] || 600
}
