import type { UseFormRegister } from 'react-hook-form'

type EmailInputProps = {
	register: UseFormRegister<any>
	isFilled?: boolean
	required?: boolean
	placeholder?: string
	id?: string
	pattern?: {
		value: RegExp
		message: string
	}
	errors?: string
	disabled?: boolean
	defaultValue?: string
}

export default function EmailInput({
	placeholder = 'Email',
	isFilled,
	id = 'email',
	register,
	required = true,
	pattern = {
		value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
		message: 'Oops, try again'
	},
	errors,
	disabled = false,
	defaultValue = ''
}: EmailInputProps) {
	return (
		<div className="flex-1 flex flex-col gap-y-2">
			<div className="form-control">
				<input
					className={`input ${isFilled ? 'is-filled' : ''}`}
					type="email"
					autoComplete="username"
					defaultValue={defaultValue}
					id={id}
					required={required}
					disabled={disabled}
					{...(!disabled && {
						...register(id, {
							required,
							maxLength: 200,
							pattern,
							disabled
						})
					})}
				/>
				<label htmlFor={id} className="label">
					<span className="placeholder">{placeholder}</span>
				</label>
			</div>
			{errors && <p className="block py-0 error-message">{errors}</p>}
		</div>
	)
}
