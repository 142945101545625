// Message for new password
import AuthTextNodes from '@data/i18n/auth.json'

export default function NewPasswordBanner() {
	return (
		<div className="rounded-md bg-bbx-spark/10 flex p-4 w-full max-w-[800px] mb-10">
			<i className="fa-solid fa-bell-exclamation flex-none text-bbx-spark pl-1 pr-4 pt-1" />
			<p className="text-sm">
				<strong className="font-medium">{AuthTextNodes.importantNotice.title}</strong>
				<br />
				<span dangerouslySetInnerHTML={{ __html: AuthTextNodes.importantNotice.description }} />
			</p>
		</div>
	)
}
