import { useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'

import { signIn } from 'next-auth/react'
import Link from 'next/link'

import useBrazeIntegration from '@context/useBrazeIntegration'
import parsePhoneNumber from 'libphonenumber-js'
import { useForm } from 'react-hook-form'
import type { SubmitHandler } from 'react-hook-form'

import AuthTextNodes from '@data/i18n/auth.json'
import CommonTextNodes from '@data/i18n/common.json'

import type { AuthProps } from '@lib/types/Auth'
import { isUK, isUs } from '@lib/utils/lang'
import { getRetryTimeout } from '@lib/utils/twilio'

import Spinner from '@components/Spinner'
import EmailInput from '@components/form-controls/EmailInput'
import TextInput from '@components/form-controls/TextInput'
import AuthFooter from '@components/profile/AuthFooter'

interface RegistrationFormInput {
	firstName: string
	lastName: string
	email: string
	phone: string
	acceptsMarketing: boolean
	password: string
	errorOtherShopify: string
	code: string
}

type RegistartionFormParams = {
	authStep: AuthProps['step']
	setAuthStep: Dispatch<SetStateAction<AuthProps['step']>>
	subscriptionBox: AuthProps['subscriptionBox']
}

// declare global {
// 	interface Window {
// 		// heap: any
// 	}
// }

export default function RegistrationForm({
	subscriptionBox,
	setAuthStep,
	authStep
}: RegistartionFormParams) {
	const [showPassword, setshowPassword] = useState(false)
	const [loading, setLoading] = useState(false)
	const [attempts, setAttempts] = useState(0)
	const [remainingSeconds, setRemainingSeconds] = useState(0)
	const [termsAndPolicies, setTermsAndPolicies] = useState(isUs())

	const {
		register,
		unregister,
		handleSubmit,
		setError,
		clearErrors,
		watch,
		getValues,
		formState: { errors }
	} = useForm<RegistrationFormInput>({
		defaultValues: {
			acceptsMarketing: isUs()
		}
	})

	const { brazeFunction } = useBrazeIntegration()

	// Watch email input to style when has value
	const emailInput = watch('email')

	const formatPhoneNumber = (rawPhone: string): string | undefined => {
		const phoneNumber = parsePhoneNumber(rawPhone, 'US')
		return phoneNumber?.format('E.164')
	}

	useEffect(() => {
		if (attempts > 0 && remainingSeconds > 0)
			setTimeout(() => setRemainingSeconds(remainingSeconds - 1), 1000)
	}, [attempts, remainingSeconds])

	const handleSignUpClick = async (data: any) => {
		setLoading(true)
		const { phone: rawPhone, email, password } = data
		const phone = formatPhoneNumber(rawPhone)

		// Verify phone number
		const res = await fetch('/api/verify', {
			method: 'POST',
			body: JSON.stringify({ phone, email, password })
		})

		if (!res.ok) {
			const { error } = await res.json()

			setError('errorOtherShopify', {
				type: 'shopify',
				message: error
			})
			setLoading(false)
			return
		}
		const respBody = await res.json()
		const { attempts: numAttempts } = respBody
		setAttempts(numAttempts || 0)
		setRemainingSeconds(getRetryTimeout(numAttempts))
		setAuthStep('verify-phone')
		setLoading(false)
	}

	const handleRetryClick = async (data: any) => {
		setLoading(true)
		const { phone: rawPhone } = data
		const phone = formatPhoneNumber(rawPhone)

		// Retry verify phone number
		const res = await fetch('/api/verify/retry', {
			method: 'POST',
			body: JSON.stringify({ phone })
		})

		if (!res.ok) {
			const { error } = await res.json()

			setError('errorOtherShopify', {
				type: 'shopify',
				message: error
			})
			setLoading(false)
			return
		}
		const respBody = await res.json()
		const { attempts: numAttempts } = respBody
		const currentAttempts = attempts + 1
		const newAttempts = numAttempts > currentAttempts ? numAttempts : currentAttempts
		setAttempts(newAttempts)
		setRemainingSeconds(getRetryTimeout(newAttempts))
		setLoading(false)
	}

	const onSubmit: SubmitHandler<RegistrationFormInput> = async (data) => {
		setLoading(true)
		const { email, password } = data

		// Create customer and verify phone number
		const createCustomerResponse = await fetch('/api/verify/check-and-create-customer', {
			method: 'POST',
			body: JSON.stringify({ ...data })
		})

		// Successful registration, sign the user in
		if (createCustomerResponse.ok) {
			const status: any = await signIn('credentials', {
				redirect: false,
				email,
				password
			})

			// Should not get to this point
			if (!status.ok) {
				setError('errorOtherShopify', {
					type: 'shopify',
					message: 'An error occurred. Please try again later.'
				})
			}

			// Analytics
			// COMMENTING OUT DUE TO HEAP PROD BUG: https://sentry.io/organizations/birchbox-tp/issues/3555367095
			// if (typeof window !== 'undefined' && window?.heap) {
			// 	window?.heap?.identify(data?.email)
			// }

			const createCustomerResponseData = await createCustomerResponse.json()

			// Braze analytics
			if (createCustomerResponseData?.data?.brazeExternalId) {
				const braze = await brazeFunction()

				if (braze) {
					braze.changeUser(createCustomerResponseData?.data?.brazeExternalId)
					braze.openSession()
					braze.getUser()?.setEmail(email)
				}
			}

			// DO NOT REDIRECT; WILL BE HANDLED IN THE PARENT COMPONENT
		} else {
			const { errors: customerResponseErrors } = await createCustomerResponse.json()

			// TODO: show all errors?
			if (customerResponseErrors && customerResponseErrors.length > 0) {
				setError('errorOtherShopify', {
					type: 'shopify',
					message: customerResponseErrors[0].message
				})
			}
			setLoading(false)
		}
	}

	return (
		<div className="flex flex-col">
			{errors?.errorOtherShopify?.message && (
				<p className="py-3 pl-5 mb-4 bg-red-100 rounded-sm error-message">
					{errors?.errorOtherShopify?.message}
				</p>
			)}

			<form
				onSubmit={(ev) => {
					// prevent default so that form doesn't try to automatically submit
					ev.preventDefault()
					clearErrors()
					register('code')
					handleSubmit(onSubmit)()
				}}
				method="post"
			>
				<fieldset
					className={`flex flex-col justify-center gap-6  ${
						authStep === 'verify-phone' ? 'hidden' : ''
					}`}
				>
					<div className="grid grid-cols-2 gap-x-6">
						{/* First name */}
						<TextInput
							register={register}
							id="first-name"
							placeholder={AuthTextNodes.signup.firstName}
							autoComplete="given-name"
						/>

						{/* Last name */}
						<TextInput
							register={register}
							id="last-name"
							placeholder={AuthTextNodes.signup.lastName}
							autoComplete="family-name"
						/>
					</div>

					{/* Email */}
					<EmailInput register={register} errors={errors?.email?.message} isFilled={!!emailInput} />

					<div>
						{/* Marketing checkbox */}
						{/* TODO: Why didn't we use Checkbox component? */}

						{!isUs() && (
							<label htmlFor="termsAndPolicies" className="flex pt-4 gap-x-3">
								<input
									className="checkbox"
									type="checkbox"
									id="termsAndPolicies"
									checked={termsAndPolicies}
									onChange={(e) => setTermsAndPolicies(e.target.checked)}
								/>
								<span className="flex-1 text-xs text-gray-500">
									{AuthTextNodes.signup.termsAndPolicies[0]}{' '}
									<Link legacyBehavior href={CommonTextNodes.termsHref}>
										<a className="underline">{AuthTextNodes.signup.termsAndPolicies[1]}</a>
									</Link>{' '}
									{AuthTextNodes.signup.termsAndPolicies[2]}{' '}
									<Link legacyBehavior href={CommonTextNodes.privacyHref}>
										<a className="underline">{AuthTextNodes.signup.termsAndPolicies[3]}</a>
									</Link>
									{isUK() ? '.' : <>&nbsp;{AuthTextNodes.signup.termsAndPolicies[4]}.</>}
								</span>
							</label>
						)}

						<label htmlFor="acceptMarketing" className="flex gap-x-3">
							<input
								className="checkbox"
								type="checkbox"
								id="acceptMarketing"
								{...register('acceptsMarketing')}
							/>
							<span className="flex-1 text-xs text-gray-500">
								{AuthTextNodes.signup.acceptsMarketing}
							</span>
						</label>

						{!isUs() && (
							<p className="pt-2 text-xs text-gray-500">
								{AuthTextNodes.signup.legalDetails[0]}{' '}
								<Link legacyBehavior href={CommonTextNodes.privacyHref}>
									<a className="underline">{AuthTextNodes.signup.legalDetails[1]}</a>
								</Link>
								.
							</p>
						)}
					</div>

					{/* Password */}
					{/* TODO: Why doesn't this use the PasswordInput component? */}
					<div className="form-control">
						<input
							className={`input pr-16 ${errors?.phone ? 'error' : ''}`}
							type={showPassword ? 'text' : 'password'}
							autoComplete="new-password"
							required
							id="password"
							{...register('password', { required: true })}
						/>

						{/**
						 * The ESLint rule is disabled here because it fails to find the
						 * input name since it's handled by react-hook-form's register above.
						 * TODO: replace this with PasswordInput component
						 */}
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label htmlFor="password" className="label">
							{AuthTextNodes.signup.password}
						</label>

						<button
							type="button"
							onClick={() => setshowPassword(!showPassword)}
							className="password-view-toggle-button"
						>
							{showPassword ? AuthTextNodes.signup.hidePassword : AuthTextNodes.signup.showPassword}
						</button>
					</div>

					<button
						className="flex justify-center button button-primary gap-x-2"
						disabled={!termsAndPolicies || loading}
						type="button"
						onClick={() => {
							clearErrors('errorOtherShopify')
							handleSubmit(onSubmit)()
						}}
					>
						{/* TODO: Better spinner here */}
						{loading && <Spinner />}
						<span>{AuthTextNodes.signup.cta}</span>
					</button>
				</fieldset>

				{/* Two-factor code */}
				<fieldset
					className={`flex flex-col gap-y-4 mt-4 ${authStep === 'verify-phone' ? '' : 'hidden'}`}
				>
					<h2 className="font-medium">{AuthTextNodes.phoneVerification.title}</h2>
					<p className="text-sm">
						{AuthTextNodes.phoneVerification.description}
						{': '}
						<b>{getValues('phone')}</b>
					</p>

					<div className="form-control">
						<input
							type="text"
							className="input"
							id="auth-code"
							required
							autoComplete="one-time-code"
							{...register('code')}
						/>
						{/* 
              FIXME: this could be solved if we replace it with the TextInput component
                     instead of using the elements directly. 
                     We're duplicating code here.
            */}
						{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
						<label htmlFor="auth-code" className="label">
							{AuthTextNodes.phoneVerification.inputLabel}
						</label>
					</div>
					<button className="button button-primary" disabled={loading} type="submit">
						{/* TODO: Better spinner here */}
						{loading && <Spinner />}
						{AuthTextNodes.phoneVerification.cta}
					</button>
					{remainingSeconds > 0 && (
						<p className="text-sm">
							{AuthTextNodes.phoneVerification.resendIn[0]}
							<b>
								{' '}
								{remainingSeconds} {AuthTextNodes.phoneVerification.resendIn[1]}
							</b>
						</p>
					)}
					<button
						className="button button-secondary"
						disabled={loading || remainingSeconds > 0}
						type="button"
						onClick={() => {
							clearErrors('errorOtherShopify')
							unregister('code')
							handleSubmit(handleRetryClick)()
						}}
					>
						{/* TODO: Better spinner here */}
						{loading && <Spinner />}
						{AuthTextNodes.phoneVerification.retry}
					</button>
				</fieldset>
			</form>

			{/* Decorative Or with horizontal lines */}
			<div className="flex gap-x-1.5 items-center my-10">
				<div aria-hidden="true" className="w-full h-px bg-gray-200" />
				<p className="font-medium text-gray-500">{AuthTextNodes.signUpTeaser.or}</p>
				<div aria-hidden="true" className="w-full h-px bg-gray-200" />
			</div>

			{subscriptionBox ? (
				<button
					className="button button-secondary"
					type="button"
					onClick={() => setAuthStep('login')}
				>
					{AuthTextNodes.login.cta}
				</button>
			) : (
				<Link legacyBehavior href="/profile/subscriptions">
					<a className="button button-secondary">{AuthTextNodes.login.cta}</a>
				</Link>
			)}

			<div className="text-xs text-center max-w-[200px] mx-auto mt-24">
				<AuthFooter />
			</div>
		</div>
	)
}
